import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import useDarkMode from '../hooks/useDarkMode'
import { useState } from 'react'
import { toast } from 'react-toastify'
import tableImg from '../img/table.png'
import activeTable from '../img/active-table.png'
import { useGlobalContext } from '../context'

const Delivery = ({ setCartOn }) => {
  const restaurantWhatsApp = '+966505535257' // put your number here with country key i.e. (+20)

  const [tableOn, setTableOn] = useState(false)
  const [value, setValue] = useState(null)
  const navigate = useNavigate()
  const { cartData, setCartData } = useGlobalContext()
  useDarkMode()

  const total = cartData
    .reduce((acc, item) => acc + item.price, 0)
    .toLocaleString('en-US')
  const date = new Date(Date.now())
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'مساءً' : 'صباحاً'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const time = `${hours}:${minutes} ${ampm}`

  const orderTotal = cartData
    .reduce((acc, item) => acc + item.price, 0)
    .toLocaleString('en-US')

  const confirmOrder = () => {
    setTableOn(false)
    const message = `%0A*نوع الطلب* : محلي %0A*رقم الطاولة* : ${value} %0A---------------------------%0A${cartData
      .map((item) => {
        let size = ''
        let extras = ''
        let edits = ''
        if (item.size) {
          switch (item.size.name) {
            case 'small':
              size = 'صغير'
              break
            case 'medium':
              size = 'وسط'
              break
            case 'large':
              size = 'كبير'
              break
            default:
              size = ''
          }
        }
        if (item.extra && item.extra.length > 0) {
          extras = `%2A الإضافات %2A%3A %0A${item.extra
            .map((e) => `%20 %20 • ${e.name}`)
            .join('%0A')} %0A`
        }
        if (item.edits && item.edits.length > 0) {
          edits = `%2A التعديلات %2A%3A %0A${item.edits
            .map((e) => `%20 %20 • ${e}`)
            .join('%0A')}`
        }
        return `%2A الصنف %2A%3A ${item.name} %0A${
          size ? `%2A الحجم %2A%3A ${size} %0A` : ''
        }%2A الكمية %2A%3A ${item.quantity} %0A%2A السعر %2A%3A ${
          item.price
        } ريال %0A${extras ? extras : ''}${edits ? edits : ''}`
      }).join('%0A---------------------------%0A')}
      %0A---------------------------%0A %2A مبلغ الطلب %2A%3A ${total}ريال %0A%2A المجموع %2A%3A ${orderTotal} ريال %0A%2A وقت الطلب %2A%3A ${time}`

    window.open(
      `https://api.whatsapp.com/send?phone=${restaurantWhatsApp}&text=${message}`
    )

    toast.success('تم تأكيد الطلب بنجاح')
    setCartData([])
    navigate('/')
  }

  const handleClose = (e) => {
    if (e.target.id === 'container') {
      setTableOn(false)
      setValue(null)
    }
  }

  const handleBack = () => {
    navigate(-1)
    setCartOn(true)
  }

  return (
    <>
      <div className='w-full sticky top-0 z-[50] inset-0 bg-white max-w-md mx-auto h-16 top-auto shadow-[1px_1px_8px_#597c8066] py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 mx-0.5 text-brown-400'>
                مطعم القارب
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Algarib Resturant
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-[#597c80] hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={handleBack}
        />
      </div>
      <div
        id='container'
        onClick={handleClose}
        className='h-[calc(100vh-225px)] md:h-[calc(100vh-136px)] dark:bg-gray-700 w-full'
      >
        <h2 className='text-2xl md:text-2xl font-bold text-primaryGreen-500 text-center py-4 dark:text-gray-200'>
         اختر رقم طاولتك
        </h2>
        <div
          className={`flex items-center justify-center flex-wrap gap-4 p-4 transition-all ${
            tableOn ? 'blur-none' : ''
          }`}
        >
          {Array.from({ length: 30 }, (_, i) => i + 1).map((table, i) => (
            <div
              className={`relative flex items-end justify-center w-20 h-20 rounded-lg text-white text-2xl overflow-hidden border-2 border-main-900 ${
                value === table ? 'scale-105' : ''
              }`}
              key={table}
              onClick={() => {
                setValue((prev) => (prev ? null : table))
                setTableOn((prev) => !prev)
              }}
            >
              <img
                src={value === table ? activeTable : tableImg}
                alt='table'
                className='absolute inset-0 w-full h-full p-1'
              />
              <button
                className={`font-bold ${
                  value === table
                    ? 'text-main-900 dark:text-white text-2xl'
                    : 'text-black dark:text-gray-200 text-lg'
                }`}
              >
                {table}
              </button>
            </div>
          ))}
        </div>
        <div
          className={`fixed w-full sm:w-[448px] flex flex-col justify-end dark:bg-gray-900 gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems transition-all duration-500 ${
            tableOn ? 'bottom-0' : '-bottom-[100vh]'
          }`}
        >
          <div className='flex items-center justify-center'>
            <div className='relative flex items-end justify-center w-28 h-28 rounded-lg text-white text-2xl overflow-hidden'>
              <img
                src={activeTable}
                alt='activeTable'
                className='absolute inset-0 w-full h-full p-1'
              />
              <button className='text-main-900 font-bold text-3xl dark:text-white'>
                {value}
              </button>
            </div>
          </div>
          <div className='flex items-center gap-4 mt-4'>
            <button
              className='py-2 w-full rounded-lg bg-red-400 text-white'
              onClick={() => {
                setTableOn(false)
                setValue(null)
              }}
            >
              إلغاء
            </button>
            <button
              className='py-2 w-full rounded-lg bg-main-900 text-white'
              onClick={confirmOrder}
            >
              تأكيد الطلب
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Delivery
