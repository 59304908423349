import { FaSnapchat, FaInstagram, FaWhatsapp, FaTiktok, FaPhoneAlt } from 'react-icons/fa'
import ReciveFromStore from '../components/ReciveFromStore'
import TableNumber from '../components/TableNumber'
import Transport from '../components/Transport'

export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: FaSnapchat,
    href: 'https://www.snapchat.com/add/alqarib2',
  },
  {
    id: 2,
    text: "تيك توك",
    icon: FaTiktok,
    href: "https://www.tiktok.com/@alqarib_1",
  },
  {
    id: 3,
    text: 'إنستجرام',
    icon: FaInstagram,
    href: 'https://www.instagram.com/',
  },
  {
    id: 4,
    text: 'واتساب',
    icon: FaWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966505535257',
  },
  { id: 5, text: 'اتصل بنا', icon: FaPhoneAlt, href: 'tel:0505535257' },
]
export const data = [
   {
        id: 1,
        title: 'الماكولات البحرية',
        image: '../../../img/list/fish.png',
        selected: true,
        items: [
            {
                id: 1,
                title: 'استاكوزا',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 90,
                calories: 450,
                selected: false,
            },
            {
                id: 2,
                title: 'جمبري جامبو',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 85,
                calories: 495,
                selected: false,
            },
            {
                id: 3,
                title: 'سلمون',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 85,
                calories: 450,
                selected: false,
            },
            {
                id: 4,
                title: 'كنعد',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 60,
                calories: 450,
                selected: false,
            },
            {
                id: 5,
                title: 'ناجل',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 70,
                calories: 450,
                selected: false,
            },
            {
                id: 6,
                title: 'هامور',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 52,
                calories: 460,
                selected: false,
            },
            {
                id: 7,
                title: 'شعور',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 46,
                calories: 450,
                selected: false,
            },
            {
                id: 8,
                title: 'بياض',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 52,
                calories: 450,
                selected: false,
            },
            {
                id: 9,
                title: 'قاروص',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 52,
                calories: 420,
                selected: false,
            },
            {
                id: 10,
                title: 'دنيس',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 52,
                calories: 420,
                selected: false,
            },
            {
                id: 11,
                title: 'فيلية',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 40,
                calories: 450,
                selected: false,
            },
            {
                id: 12,
                title: 'حبار',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 35,
                calories: 450,
                selected: false,
            },
            {
                id: 122,
                title: 'كابوريا',
                description: '500 غرام',
                image: '../../img/items.png',
                price: 40,
                calories: 465,
                selected: false,
            },
        ]
    },
    {
        id: 2,
        title: 'الطواجن والأطباق البحرية',
        image: '../../../img/list/fish1.png',
        selected: false,
        items: [
            {
                id: 13,
                title: 'طاجن جمبري',
                description: '',
                image: '../../img/items.png',
                price: 60,
                calories: 180,
                selected: false,
            },
            {
                id: 14,
                title: 'طاجن جمبري بالكريمه',
                description: '',
                image: '../../img/items.png',
                price: 60,
                calories: 380,
                selected: false,
            },
            {
                id: 15,
                title: 'طاجن كنعد سمسم',
                description: '',
                image: '../../img/items.png',
                price: 55,
                calories: 233,
                selected: false,
            },
            {
                id: 16,
                title: 'طاجن سمك بالخضار',
                description: '',
                image: '../../img/items.png',
                price: 37,
                calories: 400,
                selected: false,
            },
            {
                id: 17,
                title: 'طبق سلمون ايطالي',
                description: '',
                image: '../../img/items.png',
                price: 40,
                calories: 400,
                selected: false,
            },
            {
                id: 18,
                title: 'طبق سلمون اسباني',
                description: '',
                image: '../../img/items.png',
                price: 55,
                calories: 55,
                selected: false,
            },
        ]
    },
    {
        id: 3,
        title: 'المطبخ الأسيوي',
        image: '../../../img/list/tteokbokki.png',
        selected: false,
        items: [
            {
                id: 19,
                title: 'مسالا دجاج',
                description: '',
                image: '../../img/items.png',
                price: 37,
                calories: 830,
                selected: false,
            },
            {
                id: 20,
                title: 'مسالا روبيان',
                description: '',
                image: '../../img/items.png',
                price: 40,
                calories: 650,
                selected: false,
            },
            {
                id: 21,
                title: 'مسالا سمك',
                description: '',
                image: '../../img/items.png',
                price: 40,
                calories: 690,
                selected: false,
            },
            {
                id: 22,
                title: 'دجاج زبده',
                description: '',
                image: '../../img/items.png',
                price: 37,
                calories: 575,
                selected: false,
            },
            {
                id: 23,
                title: 'دجاج تشيلي',
                description: '',
                image: '../../img/items.png',
                price: 38,
                calories: 650,
                selected: false,
            },
            {
                id: 24,
                title: 'دجاج دراقون',
                description: '',
                image: '../../img/items.png',
                price: 38,
                calories: 620,
                selected: false,
            },
            {
                id: 25,
                title: 'دجاج مكسيكي',
                description: '',
                image: '../../img/items.png',
                price: 38,
                calories: 680,
                selected: false,
            },
            {
                id: 26,
                title: 'دجاج بالثوم الخاص',
                description: '',
                image: '../../img/items.png',
                price: 35,
                calories: 672,
                selected: false,
            },
            {
                id: 27,
                title: 'دجاج تندوري',
                description: '',
                image: '../../img/items.png',
                price: 35,
                calories: 1050,
                selected: false,
            },
            {
                id: 28,
                title: 'تكا ملاي',
                description: '',
                image: '../../img/items.png',
                price: 35,
                calories: 1070,
                selected: false,
            },
            {
                id: 29,
                title: 'دجاج سيشوان',
                description: '',
                image: '../../img/items.png',
                price: 38,
                calories: 750,
                selected: false,
            },
            {
                id: 30,
                title: 'سواجن تكا',
                description: '',
                image: '../../img/items.png',
                price: 33,
                calories: 1060,
                selected: false,
            },
            {
                id: 31,
                title: 'تندوري روتي',
                description: '',
                image: '../../img/items.png',
                price: 1,
                calories: 152,
                selected: false,
            },
            {
                id: 32,
                title: 'خبز نان',
                description: '',
                image: '../../img/items.png',
                price: 2,
                calories: 121,
                selected: false,
            },
            {
                id: 33,
                title: 'نان بالجبن',
                description: '',
                image: '../../img/items.png',
                price: 2,
                calories: 352,
                selected: false,
            },
            {
                id: 34,
                title: 'نان زبده',
                description: '',
                image: '../../img/items.png',
                price: 2,
                calories: 313,
                selected: false,
            },
            {
                id: 35,
                title: 'نان بالبصل والثوم',
                description: '',
                image: '../../img/items.png',
                price: 2,
                calories: 260,
                selected: false,
            },
        ]
    },
    {
        id: 4,
        title: 'المطبخ الإيطالي',
        image: '../../../img/list/pasta.png',
        selected: false,
        items: [
            {
                id: 36,
                title: 'فيتوتشيني الفريدو',
                description: '',
                image: '../../img/items.png',
                price: 30,
                calories: 780,
                selected: false,
            },
            {
                id: 37,
                title: 'فيتوتشيني بستوا باستا',
                description: '',
                image: '../../img/items.png',
                price: 25,
                calories: 780,
                selected: false,
            },
            {
                id: 38,
                title: 'مكرونة باستا خضار',
                description: '',
                image: '../../img/items.png',
                price: 27,
                calories: 1289,
                selected: false,
            },
            {
                id: 39,
                title: 'مكرونة باستا دجاج',
                description: '',
                image: '../../img/items.png',
                price: 34,
                calories: 1411,
                selected: false,
            },
            {
                id: 40,
                title: 'مكرونة بشاميل بالخضار',
                description: '',
                image: '../../img/items.png',
                price: 25,
                calories: 1286,
                selected: false,
            },
            {
                id: 41,
                title: 'مكرونة بشاميل بالدجاج',
                description: '',
                image: '../../img/items.png',
                price: 35,
                calories: 1509,
                selected: false,
            },
            {
                id: 42,
                title: 'مكرونة باشميل بالروبيان',
                description: '',
                image: '../../img/items.png',
                price: 46,
                calories: 1557,
                selected: false,
            },
            {
                id: 43,
                title: 'مكرونة اسباغيتي بالخضار',
                description: '',
                image: '../../img/items.png',
                price: 30,
                calories: 1286,
                selected: false,
            },
            {
                id: 44,
                title: 'مكرونه اسباغيتي بالدجاج',
                description: '',
                image: '../../img/items.png',
                price: 35,
                calories: 1320,
                selected: false,
            },
            {
                id: 45,
                title: 'بيتزا ايطاليه',
                description: '',
                image: '../../img/items.png',
                price: 25,
                calories: 223,
                selected: false,
            },
            {
                id: 46,
                title: 'بيتزا فواكة البحر',
                description: '',
                image: '../../img/items.png',
                price: 30,
                calories: 248,
                selected: false,
            },
        ]
    },
    {
        id: 5,
        title: 'قسم الأرز',
        image: '../../../img/list/roz.png',
        selected: false,
        items: [
            {
                id: 47,
                title: 'رز ابيض',
                description: '',
                image: '../../img/items.png',
                price: 8,
                calories: 917,
                selected: false,
            },
            {
                id: 48,
                title: 'رز صيادية',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 890,
                selected: false,
            },
            {
                id: 49,
                title: 'رز مشكل',
                description: '',
                image: '../../img/items.png',
                price: 16,
                calories: 1180,
                selected: false,
            },
            {
                id: 50,
                title: 'رز صيني بالخضار',
                description: '',
                image: '../../img/items.png',
                price: 16,
                calories: 350,
                selected: false,
            },
            {
                id: 51,
                title: 'رز مقلي بالدجاج',
                description: '',
                image: '../../img/items.png',
                price: 27,
                calories: 1480,
                selected: false,
            },
            {
                id: 52,
                title: 'رز مقلي بالجمبري',
                description: '',
                image: '../../img/items.png',
                price: 30,
                calories: 1380,
                selected: false,
            },
            {
                id: 53,
                title: 'رز برياني',
                description: '',
                image: '../../img/items.png',
                price: 15,
                calories: 124,
                selected: false,
            },
            {
                id: 54,
                title: 'رز برياني بالخضار',
                description: '',
                image: '../../img/items.png',
                price: 25,
                calories: 236,
                selected: false,
            },
            {
                id: 55,
                title: 'رز برياني بالدجاج',
                description: '',
                image: '../../img/items.png',
                price: 35,
                calories: 311,
                selected: false,
            },
        ]
    },
    {
        id: 6,
        title: 'الشوربات',
        image: '../../../img/list/soop.png',
        selected: false,
        items: [
            {
                id: 56,
                title: 'شوربة سي فود القارب',
                description: '',
                image: '../../img/items.png',
                price: 17,
                calories: 481,
                selected: false,
            },
            {
                id: 57,
                title: 'شوربة جمبري بالكريمة',
                description: '',
                image: '../../img/items.png',
                price: 17,
                calories: 570,
                selected: false,
            },
            {
                id: 58,
                title: 'شوربة دجاج بالكريمة ',
                description: '',
                image: '../../img/items.png',
                price: 15,
                calories: 451,
                selected: false,
            },
            {
                id: 59,
                title: 'شوربه خضار',
                description: '',
                image: '../../img/items.png',
                price: 12,
                calories: 253,
                selected: false,
            },
            {
                id: 60,
                title: 'شوربه عدس',
                description: '',
                image: '../../img/items.png',
                price: 12,
                calories: 283,
                selected: false,
            },
            {
                id: 61,
                title: 'شوربه بروكلي',
                description: '',
                image: '../../img/items.png',
                price: 12,
                calories: 190,
                selected: false,
            },
            {
                id: 62,
                title: 'شوربه نودلز',
                description: '',
                image: '../../img/items.png',
                price: 12,
                calories: 155,
                selected: false,
            },
        ]
    },
    {
        id: 7,
        title: 'المشويات',
        image: '../../../img/list/kebab.png',
        selected: false,
        items: [
            {
                id: 63,
                title: 'كباب دجاج',
                description: '',
                image: '../../img/items.png',
                price: 28,
                calories: 240,
                selected: false,
            },
            {
                id: 64,
                title: 'شيش طاووق',
                description: '',
                image: '../../img/items.png',
                price: 28,
                calories: 560,
                selected: false,
            },
            {
                id: 65,
                title: 'مشاوي مشكل صغير',
                description: '',
                image: '../../img/items.png',
                price: 33,
                calories: 3380,
                selected: false,
            },
            {
                id: 66,
                title: 'مشاوي مشكل كبير',
                description: '',
                image: '../../img/items.png',
                price: 64,
                calories: 2316,
                selected: false,
            },
            {
                id: 67,
                title: 'حبة دجاج مع الرز',
                description: '',
                image: '../../img/items.png',
                price: 46,
                calories: 1145,
                selected: false,
            },
            {
                id: 68,
                title: 'نصف دجاج مع الرز',
                description: '',
                image: '../../img/items.png',
                price: 23,
                calories: 548,
                selected: false,
            },
            {
                id: 69,
                title: 'نصف دجاج بدون الرز',
                description: '',
                image: '../../img/items.png',
                price: 16,
                calories: 388,
                selected: false,
            },
        ]
    },
    {
        id: 8,
        title: 'المقبلات الباردة',
        image: '../../../img/list/vegetables.png',
        selected: false,
        items: [
            {
                id: 70,
                title: 'حمص',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 270,
                selected: false,
            },
            {
                id: 71,
                title: 'متبل',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 262,
                selected: false,
            },
            {
                id: 72,
                title: 'تبوله',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 160,
                selected: false,
            },
            {
                id: 73,
                title: 'بابا غنوج',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 190,
                selected: false,
            },
            {
                id: 74,
                title: 'ورق عنب',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 254,
                selected: false,
            },
            {
                id: 75,
                title: 'مقبلات وسط',
                description: '',
                image: '../../img/items.png',
                price: 20,
                calories: 390,
                selected: false,
            },
            {
                id: 76,
                title: 'مقبلات كبير',
                description: '',
                image: '../../img/items.png',
                price: 25,
                calories: 816,
                selected: false,
            },
        ]
    },
    {
        id: 9,
        title: 'المقبلات الساخنة والإيدامات',
        image: '../../../img/list/btatis.png',
        selected: false,
        items: [
            {
                id: 77,
                title: 'بطاطس مقلية',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 312,
                selected: false,
            },
            {
                id: 78,
                title: 'سمبوسه دجاج',
                description: '5 قطع',
                image: '../../img/items.png',
                price: 10,
                calories: 315,
                selected: false,
            },
            {
                id: 79,
                title: 'سمبوسه خضار',
                description: '5 قطع',
                image: '../../img/items.png',
                price: 10,
                calories: 220,
                selected: false,
            },
            {
                id: 80,
                title: 'سمبوسه جبن',
                description: '5 قطع',
                image: '../../img/items.png',
                price: 10,
                calories: 315,
                selected: false,
            },
            {
                id: 81,
                title: 'كبه شاميه',
                description: '',
                image: '../../img/items.png',
                price: 15,
                calories: 534,
                selected: false,
            },
            {
                id: 82,
                title: 'مصقع',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 438,
                selected: false,
            },
            {
                id: 83,
                title: 'ملوخيه',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 164,
                selected: false,
            },
            {
                id: 84,
                title: 'ايدام خضار',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 355,
                selected: false,
            },
            {
                id: 85,
                title: 'محشي دجاج بالصوص الخاص',
                description: '',
                image: '../../img/items.png',
                price: 35,
                calories: 408,
                selected: false,
            },
        ]
    }, 
    {
        id: 10,
        title: 'السلطات',
        image: '../../../img/list/salat.png',
        selected: false,
        items: [
            {
                id: 86,
                title: 'سلطه دينامت شرمب',
                description: '',
                image: '../../img/items.png',
                price: 40,
                calories: 380,
                selected: false,
            },
            {
                id: 87,
                title: 'سلطه سيزر',
                description: '',
                image: '../../img/items.png',
                price: 24,
                calories: 380,
                selected: false,
            },
            {
                id: 88,
                title: 'سلطه روسية',
                description: '',
                image: '../../img/items.png',
                price: 20,
                calories: 200,
                selected: false,
            },
            {
                id: 89,
                title: 'سلطه يونانية',
                description: '',
                image: '../../img/items.png',
                price: 17,
                calories: 175,
                selected: false,
            },
            {
                id: 90,
                title: 'سلطه خضراء',
                description: '',
                image: '../../img/items.png',
                price: 14,
                calories: 170,
                selected: false,
            },
            {
                id: 91,
                title: 'سلطه فتوش',
                description: '',
                image: '../../img/items.png',
                price: 14,
                calories: 240,
                selected: false,
            },
            {
                id: 92,
                title: 'سلطه جرجير',
                description: '',
                image: '../../img/items.png',
                price: 14,
                calories: 180,
                selected: false,
            },
            {
                id: 93,
                title: 'سلطه زبادي',
                description: '',
                image: '../../img/items.png',
                price: 12,
                calories: 139,
                selected: false,
            },
            {
                id: 94,
                title: 'سلطه فواكه',
                description: '',
                image: '../../img/items.png',
                price: 20,
                calories: 250,
                selected: false,
            },
        ]
    },
    {
        id: 11,
        title: 'المشروبات والعصائر',
        image: '../../../img/list/mohi.png',
        selected: false,
        items: [
            {
                id: 95,
                title: 'عصير برتقال خلاط',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 293,
                selected: false,
            },
            {
                id: 96,
                title: 'عصير برتقال كبس',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 40,
                selected: false,
            },
            {
                id: 97,
                title: 'عصير كوكتيل',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 293,
                selected: false,
            },
            {
                id: 98,
                title: 'عصير افوكادو',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 222,
                selected: false,
            },
            {
                id: 99,
                title: 'عصير اناناس',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 90,
                selected: false,
            },
            {
                id: 100,
                title: 'عصير شمبانيا',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 158,
                selected: false,
            },
            {
                id: 101,
                title: 'عصير مانجو',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 154,
                selected: false,
            },
            {
                id: 102,
                title: 'عصير فراوله',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 72,
                selected: false,
            },
            {
                id: 103,
                title: 'عصير ليمون',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 239,
                selected: false,
            },
            {
                id: 104,
                title: 'عصير جوافه',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 265,
                selected: false,
            },
            {
                id: 105,
                title: 'عصير حبحب',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 48,
                selected: false,
            },
            {
                id: 106,
                title: 'جك شمبانيا',
                description: '',
                image: '../../img/items.png',
                price: 38,
                calories: 690,
                selected: false,
            },
            {
                id: 107,
                title: 'جاك عصير',
                description: '',
                image: '../../img/items.png',
                price: 35,
                calories: 705,
                selected: false,
            },
            {
                id: 108,
                title: 'موهيتو فراولة',
                description: '',
                image: '../../img/items.png',
                price: 14,
                calories: 150,
                selected: false,
            },
            {
                id: 109,
                title: 'موهيتو توت ازرق',
                description: '',
                image: '../../img/items.png',
                price: 14,
                calories: 150,
                selected: false,
            },
            {
                id: 110,
                title: 'مشروب غازي',
                description: '',
                image: '../../img/items.png',
                price: 3,
                calories: 172,
                selected: false,
            },
            {
                id: 111,
                title: 'لبن',
                description: '',
                image: '../../img/items.png',
                price: 2,
                calories: 80,
                selected: false,
            },
            {
                id: 112,
                title: 'ماء',
                description: '',
                image: '../../img/items.png',
                price: 1,
                calories: "0",
                selected: false,
            },
        ]
    },
    {
        id: 12,
        title: 'الحلويات',
        image: '../../../img/list/soit.png',
        selected: false,
        items: [
            {
                id: 113,
                title: 'كنافة',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 292,
                selected: false,
            },
            {
                id: 114,
                title: 'كريمة',
                description: '',
                image: '../../img/items.png',
                price: 8,
                calories: 281,
                selected: false,
            },
            {
                id: 115,
                title: 'سوفلية',
                description: '',
                image: '../../img/items.png',
                price: 13,
                calories: 245,
                selected: false,
            },
            {
                id: 116,
                title: 'مرسة بلدي',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 320,
                selected: false,
            },
        ]
    },
    {
        id: 13,
        title: 'الصوصات',
        image: '../../../img/list/sauce.png',
        selected: false,
        items: [
            {
                id: 117,
                title: 'ثوم',
                description: '',
                image: '../../img/food/thom-sos.jpg',
                price: 3,
                calories: 89,
                selected: false,
            },
            {
                id: 118,
                title: 'طحينة',
                description: '',
                image: '../../img/food/thinh-sos.jpg',
                price: 3,
                calories: 35,
                selected: false,
            },
            {
                id: 119,
                title: 'تمر هندي',
                description: '',
                image: '../../img/food/tmr-sos.jpg',
                price: 3,
                calories: 40,
                selected: false,
            },
            {
                id: 120,
                title: 'سلطة حارة',
                description: '',
                image: '../../img/food/hot-sos.jpg',
                price: 4,
                calories: 82,
                selected: false,
            },
            {
                id: 121,
                title: 'صوص كوكتيل القارب',
                description: '',
                image: '../../img/food/koktil-sos.jpg',
                price: 5,
                calories: 82,
                selected: false,
            },
        ]
    },
]
export const deliveryOptions = [
  {
    id: 1,
    component: Transport,
    name: 'توصيل',
    img: '../../img/delivery/delivery.png',
  },
  {
    id: 2,
    component: ReciveFromStore,
    name: 'إستلام',
    img: '../../img/delivery/pickup.png',
  },
  {
    id: 3,
    component: TableNumber,
    name: 'إختيار الطاولة',
    img: '../../img/delivery/number.png',
  },
]
